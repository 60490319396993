import cardStyle from './cardStyle.js'
import nodeStyles from './nodeStyles.js'

import {CardData} from '../cardData/interfaces'
import fetchCardData  from '../cardData/fetchCardData.js';

import CelticCrossLayout from './CelticCrossLayout';

import {useState, useEffect, useRef} from 'react';
// Import Cytoscape (if you're in an environment that supports ES6 imports)
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const tarot_base_url = 'https://aitarotreader.com';
const tarot_backcard_url = `${tarot_base_url}/static/images/backcard.png`;
const tarot_reader_url =  `${tarot_base_url}/static/images/tarotreader.png`;

function Begin(props: any) {

  const [cards, setCards] = useState<number[]>([]);

  const [deck, setDeck] = useState<{ [key: number]: {
    name: string;
    summary: string;
    image_url: string;
    } }>({});

  const initialized = useRef(false);

  const [atCard, setAtCard] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const [debug,setDebug] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const generateRandomCard = () => {
    const randomNumber = Math.floor(Math.random() * 78) + 1;
    return randomNumber;
  };

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
        if (window.innerWidth < 576) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
  };


  useEffect(() => {

    document.body.style.backgroundColor = '#05014a';

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    if (!initialized.current) {

      toast.info('Preparing your tarot cards for your reading...', { autoClose: false });
      initialized.current = true;
      setIsLoading(true);

      const uniqueCards: number[] = [];

      if (debug) {
        uniqueCards.push(25);
        uniqueCards.push(49);
        uniqueCards.push(18);
        uniqueCards.push(14);
        uniqueCards.push(38);
        uniqueCards.push(36);
        uniqueCards.push(53);
        uniqueCards.push(23);
        uniqueCards.push(39);
        uniqueCards.push(61);
      }

      while(uniqueCards.length < 10) {
          const card = generateRandomCard();

          if (!uniqueCards.includes(card)) {
            uniqueCards.push(card);
          }
      }

      setCards([...uniqueCards]);

      const promises = uniqueCards.map((card, index) => {
        //console.log(`Fetching card data for ${card} for position ${index}`);

        return fetchCardData(card,index)
          .then((data) => ({
            card,
            data,
          }))
          .catch(error => {
            console.log(error)
            return null;
          });
      });

      Promise.all(promises)
        .then((results: Array<{ card: number; data: CardData } | null>) => {
          const updatedDeck = results.reduce((prevDeck, result) => {
            if (result && result.data) {
              prevDeck[result.card] = {
                name: result.data.name,
                summary: result.data.summary,
                image_url: `${tarot_base_url}/${result.data.image_url}`,
              };
            }
            return prevDeck;
          }, { ...deck });

          setDeck(updatedDeck);

          console.log(`Data load is complete`);
          toast.dismiss()
          setIsLoading(false);

      // Do something else with the updated deck here
      }).catch((error) => {
        console.log(error);
      });
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  },[])

  const setCard = (atCard:number) => {
    setAtCard(prevAtCard => atCard);
  }


  // TODO ask for first name and save

  // TODO ask for purpose of reading

  return (
    <div className="container-fluid d-flex flex-column" style={{ backgroundColor: '#05014a', height: '100vh' }}>
      <ToastContainer />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        {isLoading ? (
        <div className="row justify-content-center">
          <img src={tarot_reader_url} alt="Loading" />
        </div>
        ) : (
        <CelticCrossLayout
          cards={cards}
          atCard={atCard}
          setCard={setCard}
          backCard={tarot_backcard_url}
          deck={deck}
          isMobile={isMobile}
          showOverlay={showOverlay}
          setShowOverlay={setShowOverlay}
        />
        )}
      </div>
    </div>
  );
}

export default Begin;
