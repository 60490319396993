import TarotCard from './TarotCard';
import Explanation from './Explanation';

const descriptions = [
  {title: "Let's Begin", description: ''},
  {title: 'Significator', description: 'The present situation'},
  {title: 'Crossing', description: 'The immediate challenge or obstacle'},
  {title: 'Foundation', description: 'The root cause or basis of the situation'},
  {title: 'Recent Past', description: 'Events or influences that have led to the current situation'},
  {title: 'Higher Power', description: 'Spiritual or higher guidance in the situation'},
  {title: 'Near Future', description: 'What is approaching in the next few weeks or months'},
  {title: 'Advice', description: 'Guidance or suggested course of action'},
  {title: 'External Influences', description: 'People or events influencing the situation'},
  {title: 'Hopes and Fears', description: "The querent's desires and concerns"},
  {title: 'Outcome', description: 'The potential outcome or resolution'}
]


function CelticCrossLayout (props: any) {
  return (
    <div key='container' className="container">
      {props.isMobile ? (
      <div key='mobile-container' className="container">
        {props.cards.map((card:number[], index:number) => (
        <div key={`mobile-row-${index}`} className="row justify-content-center">
          <div className="col-12">
            <TarotCard key={`tarot-card-m-${index+1}`} number={index + 1} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
          </div>
        </div>
        ))}
      </div>
      ) : (
      <div className="row justify-content-center">
        <div className="col-12 col-md-9">
          <div className="row justify-content-center">
            <div className="col-12 col-md-3">
              <TarotCard key={'tarot-card-3'} number={3} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-3">
              <TarotCard number={6} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
            </div>
            <div className="col-12 col-md-3">
              <TarotCard number={1} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
              <TarotCard number={2} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
            </div>
            <div className="col-12 col-md-3">
              <TarotCard number={4} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-3">
              <TarotCard number={5} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="row">
            <TarotCard number={10} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
          </div>
          <div className="row">
            <TarotCard number={9} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
          </div>
          <div className="row">
            <TarotCard number={8} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
          </div>
          <div className="row">
            <TarotCard number={7} descriptions={descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard}/>
          </div>
        </div>
      </div>
    )}
      <div className="row justify-content-center">
        <div className="col-12">
          <Explanation key='Explanation' atCard={props.atCard} descriptions={descriptions} backCard={props.backCard} deck={props.deck} cards={props.cards} setShowOverlay={props.setShowOverlay} showOverlay={props.showOverlay} setCard={props.setCard} />
        </div>
      </div>
    </div>
  )
}

export default CelticCrossLayout;
