import React from 'react';
import logo from './logo.svg';
import './App.css';
import Begin from './components/Begin';

function App() {
  return (
    <Begin/>
  );
}

export default App;
